import { Router } from '@angular/router';
import { LoginService } from './../services/login.service';
import { Component, OnInit } from '@angular/core';
import { ReCaptchaService } from '../services/re-captcha.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  dni: number;
  password: string;
  iniciando: boolean = false;
  error: boolean = false;

  constructor(
    private recaptchaservice: ReCaptchaService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private loginService: LoginService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  login() {

    this.error = false;

    if (this.iniciando) {
      return;
    }
    this.iniciando = true;

    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
        this.recaptchaservice.getScore(token).subscribe(
          (res: any) => {
            if (res.body.success == false) {
              Swal.fire('Error', 'Ha ocurrido un error, intente nuevamente mas tarde', 'error');
              return;
            }
            this.loginService.logIn({
              dni: this.dni,
              password: this.password
            }).subscribe(
              (res: any) => {
                localStorage.setItem('token', res.token);
                let decoded = JSON.parse(atob(res.token.split('.')[1]));
                localStorage.setItem('usuario', JSON.stringify(decoded.usuario));
                localStorage.setItem('menu', JSON.stringify(res.menu));
                this.router.navigate(['admin/usuarios/1']);
                this.iniciando = false;
              },
              err => {
                this.password = '';
                this.iniciando = false;
                this.error = true;
                // Swal.fire('Error', 'DNI o contraseña incorrectos', 'error');
                return;
              }
            );
          },
          err => {
            Swal.fire('Error', 'Ha ocurrido un error, intente nuevamente mas tarde', 'error');
            return;
          }
        );
      });
  }

}
