<div
  class="container loginContainer animate__animated animate__fadeIn animate__slower"
>
  <div class="row">
    <div class="col col-md-6 offset-md-3 col-lg-4 offset-lg-4">
      <div class="card">
        <div class="card-body">
          <!-- <span class="card-title">LOGO</span> -->
          <div class="row text-center">
            <div class="col">
              <img
                src="../../assets/img/logoNegro.png"
                class="logo animate__animated animate__fadeInRight animate__slower"
              />
            </div>
          </div>

          <form #f="ngForm" (ngSubmit)="login()" ngNativeValidate>
            <div class="mb-3">
              <label for="dni" class="form-label">DNI</label>
              <input
                [(ngModel)]="dni"
                name="dni"
                type="number"
                class="form-control"
                id="dni"
                required
              />
            </div>

            <div class="mb-3">
              <label for="pwd" class="form-label">Contraseña</label>
              <input
                [(ngModel)]="password"
                name="password"
                type="password"
                class="form-control"
                id="pwd"
                required
              />
            </div>

            <div *ngIf="error" class="row">
              <div class="col">
                <p style="color: red">DNI o contraseña incorrectos</p>
              </div>
            </div>

            <div class="d-grid gap-2">
              <button
                *ngIf="!iniciando"
                type="submit"
                class="btn btn-secondary"
              >
                <i class="fas fa-sign-in-alt"></i> Iniciar sesión
              </button>
            </div>
            <div class="d-grid gap-2">
              <button
                *ngIf="iniciando"
                type="submit"
                class="btn btn-secondary"
                disabled
              >
                <i class="fas fa-sign-in-alt"></i> Iniciando sesión...
              </button>
            </div>

            <div class="re">
              Este sitio está protegido por reCAPTCHA y se aplica la
              <a href="https://policies.google.com/privacy"
                >Politica de Privacidad</a
              >
              y los
              <a href="https://policies.google.com/terms"
                >Términos de Servicio</a
              >
              de Google.
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
