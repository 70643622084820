import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { HttpClientModule } from '@angular/common/http';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-AR';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
registerLocaleData(localeEs, 'es-AR');
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RecaptchaV3Module
  ],
  providers: [
    {
      provide: LOCALE_ID, useValue: 'es-AR'
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lc9b3onAAAAAGUHWEsGK5NFMThcTTwRYoYEDxlN' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
