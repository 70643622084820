<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="me-4 ms-1" [routerLink]="['/admin/usuarios/1']">
      <img src="../../../../assets/img/logoNegro.png" width="100px" alt=""
    /></a>
    <!-- <a class="navbar-brand me-5 link" [routerLink]="['/admin/usuarios/1']"
      >FuDAP</a
    > -->
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a
            [routerLink]="['usuarios/1']"
            routerLinkActive="active"
            class="nav-link"
            aria-current="page"
            href="#"
            style="font-weight: 500; text-shadow: 1px 1px 2px grey"
            ><i class="fas fa-home"></i> Inicio
          </a>
        </li>
      </ul>
      <ul class="navbar-nav me-3 ms-auto">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="font-weight: 500; text-shadow: 1px 1px 2px grey"
          >
            <i class="fa-solid fa-user me-1"></i>{{ usuario.apellido }},
            {{ usuario.nombre }}
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li *ngIf="usuario.rol == 'ADMIN'">
              <a
                [routerLink]="['/admin/profesionales']"
                class="dropdown-item link"
                ><i class="fas fa-user-md me-1"></i>Profesionales</a
              >
            </li>
            <hr *ngIf="usuario.rol == 'ADMIN'" />
            <li>
              <a class="dropdown-item link" (click)="cambiarPwd()"
                ><i class="fas fa-lock me-1"></i>Cambiar contraseña</a
              >
            </li>
            <li>
              <a class="dropdown-item link" (click)="cerrarSesion()"
                ><i class="fas fa-sign-out-alt me-1"></i>Cerrar sesión</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
