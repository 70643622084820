import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URL_SERVICIOS } from 'src/config/config';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  url = URL_SERVICIOS + 'login';

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  keepAwake() {
    return this.http.get(URL_SERVICIOS);
  }

  logIn(body) {
    return this.http.post(this.url, body);
  }

  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('menu');
    this.router.navigate(['/login']);
  }
}
