import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from 'src/config/config';

@Injectable({
  providedIn: 'root'
})
export class ReCaptchaService {

  url = URL_SERVICIOS + 'reCAPTCHA';

  constructor(private http: HttpClient) { }

  getScore(token: string) {
    let url = this.url + '?token=' + token;
    return this.http.post(url, null);
  }
}
