import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Usuario } from '../models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router
  ) { }

  canActivate(): boolean {
    let usuario: Usuario = JSON.parse(localStorage.getItem('usuario')!);
    let token = localStorage.getItem('token');
    if (!usuario || !token) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

}
